// @flow

import { stringToUrl } from '@util';

// Updated getNeighborhoodOptions function with exclusion logic and additional checks
export default function getNeighborhoodName(data: Object, excludedNeighborhoods: string[] = []): Object[] {
  if (data && data.wagtail && data.wagtail.properties) {
    const { properties } = data.wagtail;
    const neighborhoodsWithUnits = properties
      .map(property => property && property.address && property.address.neighborhood ? property.address.neighborhood[0] : null);

    const searchOptions = neighborhoodsWithUnits
      .filter(neighborhood => 
        neighborhood && 
        neighborhood.name && 
        (Array.isArray(excludedNeighborhoods) ? !excludedNeighborhoods.includes(neighborhood.name) : true)
      )
      .map(({ name }) =>
        Object.create({
          id: name,
          text: name,
          value: stringToUrl(name),
        }),
      );
    return searchOptions;
  } else return [];
}
