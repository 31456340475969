// @flow

import React, {Fragment} from 'react';
import {Block, Col} from 'jsxstyle';

import Link from '@components/Link';
import Image from '@components/Image';
import Offer from '@components/OfferPopup';
import SearchField from '@components/Search/SearchField';
import ScrollTo from '@components/ScrollTo';

import {WHITE} from '@styles/colors';

import type {Element, Node} from 'react';

type Props = {
  title?: string,
  body?: string,
  rawhtmlbody?: boolean,
  fluid?: Object,
  searchField?: Element<*>,
  questionsCallout?: Element<*>,
  scrollToLink?: Element<*>,
  sectionRef: any,
  copy: string,
  height: string,
  padding?: string,
  margin?: string,
  children?: Node,
  background?: string,
  alt?: string,
  imgStyle?: Object,
  imageContainerStyle?: Object,
  videoUrl?: string,
  imageSrc?: string,
  noOffer?: boolean,
  offerMessage?: string,
  offerMessageLocation?: string,
  offerMessageL?: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
  exLg: 'screen and (min-width: 1440px)',
};

export default ({
  title,
  body,
  rawhtmlbody,
  searchField,
  questionsCallout,
  scrollToLink,
  sectionRef,
  copy,
  height,
  padding,
  margin,
  children,
  fluid,
  background,
  alt,
  imgStyle,
  imageSrc,
  imageContainerStyle,
  videoUrl,
  noOffer,
  offerMessage,
  offerMessageLocation,
  offerMessageL,
}: Props): Element<*> => (
  <Col
    component="section"
    height={height ? height : '100vh'}
    padding={padding && padding}
    margin={margin && margin}
    width="100%"
    position="relative"
    alignItems="center"
    justifyContent="center"
    backgroundSize="cover !important"
    background={
      background
        ? background
        : 'linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3))'
    }
  >
    {videoUrl && (
      <Block height="100vh" width="100vw" zIndex="-1">
        <Block
          component="video"
          objectFit="fill"
          width="100%"
          height="100%"
          props={{
            src: videoUrl,
            autoPlay: true,
            loop: true,
            muted: true,
            playsInline: true,
            preload: 'yes',
            type: 'video/mp4',
          }}
          mediaQueries={mediaQueries}
          smObjectFit="cover"
        />
      </Block>
    )}
    {fluid && (
      <Image
        imageSrc={imageSrc}
        fluid={fluid}
        imgStyle={imgStyle}
        style={
          imageContainerStyle
            ? imageContainerStyle
            : {
                top: '0',
                bottom: '0',
                right: '0',
                left: '0',
                position: 'absolute',
                zIndex: '-1',
              }
        }
        alt={alt ? alt : 'HeroImage'}
      />
    )}
    {children ? (
      <Fragment>{children}</Fragment>
    ) : (
      <Col
        color={WHITE}
        alignItems="center"
        justifyContent="center"
        maxWidth="1360px"
        position={videoUrl && 'absolute'}
        mediaQueries={mediaQueries}
        smWidth="90%"
        smMargin="0 auto"
      >
        <Block
          component="h1"
          fontSize="4.5rem"
          textAlign="center"
          margin="0"
          mediaQueries={mediaQueries}
          smFontSize="3rem"
        >
          {title}
        </Block>
        {rawhtmlbody ? (
          <Block
            component="div"
            textAlign="center"
            margin="20px 0"
            fontSize="1.3rem"
            lineHeight="1.5rem"
            fontWeight="bold"
            width="60%"
            mediaQueries={mediaQueries}
            smWidth="90%"
            smFontSize="1rem"
            smLineHeight="1.2rem"
          >
            <div dangerouslySetInnerHTML={body}></div>
          </Block>
        ) : (
          <Block
            component="p"
            textAlign="center"
            margin="20px 0"
            fontSize="1.3rem"
            lineHeight="1.5rem"
            fontWeight="bold"
            width="60%"
            mediaQueries={mediaQueries}
            smWidth="90%"
            smFontSize="1rem"
            smLineHeight="1.2rem"
          >
            {body}
          </Block>
        )}
        {searchField && <SearchField />}
        {scrollToLink && (
          <ScrollTo
            sectionRef={sectionRef}
            copy={copy}
            scrollOptions={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            }}
          />
        )}
        {questionsCallout && (
          <Link
            href="/contact/"
            style={{
              color: WHITE,
              fontSize: '1rem',
              fontWeight: 'bold',
              textAlign: 'center',
              textTransform: 'capitalize',
              textDecoration: 'underline',
            }}
          >
            have questions? contact us
          </Link>
        )}
      </Col>
    )}
    {!noOffer && (
      <Offer
        offerLength={
          offerMessage
            ? offerMessage
            : 'The apt you want could be gone in a NY minute, and we are ready to welcome you home. Please contact us today.'
        }
        offerLocation={offerMessageLocation}
        l={offerMessageL}
      />
    )}
  </Col>
);
