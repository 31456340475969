// @flow

import React from 'react';
import {InlineBlock} from 'jsxstyle';

import {BLUE, WHITE} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  options: Object[],
  width?: string,
  padding?: string,
  border?: string,
  height?: string,
  backgroundColor?: string,
  margin?: string,
  fontSize?: string,
  fontWeight?: string,
  arrow: string,
  arrowPosition?: string,
  defaultValue: string,
  defaultValueText: string,
  capitalize?: boolean,
  handleChange: (e: Event) => void,
  selectedValue: string,
  name?: string,
  mdWidth?: string,
  smWidth?: string,
  smMargin?: string,
  mediaQueries?: Object,
  id?: string,
};

type OptionProps = {
  value: string,
  text: string,
};

const mq = {
  sm: 'screen and (max-width: 768px)',
  md: 'screen and (max-width: 1279px) and (min-width: 1025px)',
  lg: 'screen and (min-width: 1280px)',
};

const Option = ({value, text}: OptionProps) => (
  <option value={value}>{text}</option>
);

export default ({
  options,
  width,
  padding,
  height,
  border,
  backgroundColor,
  fontSize,
  fontWeight,
  arrow,
  margin,
  id,
  arrowPosition,
  defaultValue,
  defaultValueText,
  capitalize,
  handleChange,
  selectedValue,
  name,
  mediaQueries,
  mdWidth,
  smWidth,
  smMargin,
  required,
}: Props): Element<*> => (
  <InlineBlock
    component="select"
    width={width ? width : 'auto'}
    padding={padding ? padding : '20px'}
    fontSize={fontSize ? fontSize : '1.3rem'}
    fontWeight={fontWeight ? fontWeight : 'initial'}
    textTransform={capitalize && 'capitalize'}
    color={BLUE}
    height={height ? height : '80px'}
    border={border ? border : 'none'}
    borderRadius="0px"
    WebkitAppearance="none"
    WebkitBorderRadius="0px"
    MozAppearance="none"
    background={
      backgroundColor
        ? `url(${arrow}) no-repeat 95% 50% ${backgroundColor}`
        : WHITE
    }
    backgroundPositionX={arrowPosition ? arrowPosition : '94%'}
    margin={margin}
    mediaQueries={mediaQueries ? mediaQueries : mq}
    mdWidth={mdWidth ? mdWidth : width}
    smWidth={smWidth ? smWidth : width}
    smMargin={smMargin ? smMargin : 'auto'}
    props={{
      value: selectedValue ? selectedValue : '',
      onChange: e => handleChange(e),
      'aria-label': `Choose your ${defaultValue}`,
      name: name ? name : defaultValue,
      required,
      id,
    }}
  >
    <option key="default" value={defaultValue}>
      {defaultValueText ? defaultValueText : defaultValue}
    </option>
    {options.map(option => (
      <Option
        key={option.id ? option.id.toString() : option}
        value={option.value ? option.value : option}
        text={option.text ? option.text : option}
      />
    ))}
  </InlineBlock>
);
