// @flow

import React, {Component, Fragment} from 'react';
import {Block, InlineBlock, Col, Row} from 'jsxstyle';
import Link from '@components/Link';
import Close from '@components/icons/Close';
import {WHITE, ORANGE} from '@styles/colors';

type Props = {
  offerLength: string,
  offerLocation: string,
  l: string,
};

type State = {
  isActive: boolean,
};

const mediaQueries = {
  ss: 'screen and (max-width: 500px) and (min-width: 320px)',
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default class Offer extends Component<Props, State> {
  state = {
    isActive: true,
  };

  createMarkup() {
    return {__html: ''};
  }
  componentDidMount() {
    const isInStorage = window.sessionStorage.getItem('popup');
    if (typeof isInStorage === 'string' && isInStorage.length > 0) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isActive: false,
      });
    } else {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isActive: true,
      });
    }
  }

  hideOffer() {
    if (!window.sessionStorage.getItem('popup')) {
      window.sessionStorage.setItem('popup', 'exists');
    }
    this.setState({
      isActive: false,
    });
  }

  render() {
    const {offerLength, offerLocation, l} = this.props;
    return (
      <Fragment>
        {this.state.isActive && (
          <Block
            position="absolute"
            background={ORANGE}
            left="0"
            bottom="0"
            padding="30px"
            mediaQueries={mediaQueries}
            smTop="80px"
            smBottom="auto"
            smPadding="0"
            smWidth="100%"
            smHeight="45px"
          >
            <Block
              position="absolute"
              right="0.5rem"
              top="0.5rem"
              fill={WHITE}
              height="1rem"
              width="1rem"
              cursor=" pointer"
              props={{onClick: () => this.hideOffer()}}
            >
              <Close />
            </Block>
            <Col
              mediaQueries={mediaQueries}
              smFlexDirection="row"
              smHeight="100%"
            >
              <Block
                component="h4"
                textTransform="uppercase"
                color={WHITE}
                fontSize="1rem"
                maxWidth="375px !important"
                fontWeight="bold"
                lineHeight="1.4rem"
                marginBottom={l ? '20px' : '0'}
                mediaQueries={mediaQueries}
                lgMaxWidth="500px !important"
                smMaxWidth="100% !important"
                smMargin="0 10px"
                smDisplay="flex"
                smJustifyContent="center"
                smAlignItems="center"
                smFontSize="14px"
                smTextTransform="initial"
                ssFontSize="12px !important"
              >
                <div dangerouslySetInnerHTML={{__html: offerLength}} />
              </Block>
              {l ? (
                <Row component="p" color={WHITE} alignItems="center">
                  <InlineBlock
                    component="span"
                    fontSize="1.6rem"
                    marginRight="10px"
                    fontWeight="bold"
                    mediaQueries={mediaQueries}
                    smFontSize="16px"
                    ssFontSize="12px !important"
                  >
                    <Link
                      className="virtual-tour-banner-link"
                      href={l}
                      target="_self"
                    >
                      {offerLocation}
                    </Link>
                  </InlineBlock>
                </Row>
              ) : null}
            </Col>
          </Block>
        )}
      </Fragment>
    );
  }
}
