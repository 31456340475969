// @flow

import React from 'react';
import {Col} from 'jsxstyle';

import Paragraph from '@components/Paragraph';
import Arrow from '@components/icons/Arrow';
import {WHITE} from '@styles/colors';

import type {Element, Ref} from 'react';

type Props = {
  sectionRef: Ref<*>,
  copy: string,
  scrollOptions?: {
    behavior?: string,
    block?: string,
    inline?: string,
  },
};

export default ({sectionRef, copy, scrollOptions}: Props): Element<*> => (
  <Col
    alignItems="center"
    justifyContent="center"
    marginTop="40vh"
    position="absolute"
    cursor="pointer"
    props={{
      onClick: () =>
        sectionRef &&
        sectionRef.current &&
        // $FlowFixMe
        sectionRef.current.scrollIntoView({...scrollOptions}),
    }}
  >
    <Paragraph
      color={WHITE}
      fontSize="1.3rem"
      fontWeight="bold"
      textTransform="uppercase"
      textDecoration="none"
    >
      {copy}
    </Paragraph>
    <Arrow margin="0" transform="rotate(90deg)" />
  </Col>
);
