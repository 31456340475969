// @flow

import React from 'react';

import type {Element} from 'react';

type Props = {
  margin?: string,
  transform?: string,
};

export default ({margin, transform}: Props): Element<*> => (
  <svg style={{margin, transform}} width="9" height="24" viewBox="0 0 9 24">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#FF7C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1.5 22.5l6-10.5-6-10.5"
    />
  </svg>
);
