// @flow

import React, {Component} from 'react';
import {Row} from 'jsxstyle';
import {StaticQuery, graphql} from 'gatsby';

import Button from '@components/Button';
import Dropdown from '@components/Form/Dropdown';
import {ORANGE, WHITE} from '@styles/colors';
import dropdownArrow from '@data/svg/DropdownArrow.svg';
import getNeighborhoodOptions from '@util/getNeighborhoodOptions';
import {stringToUrl} from '@util';

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

type Props = {};

type State = {
  selectedValue: string,
};

export default class SearchField extends Component<Props, State> {
  state = {
    selectedValue: '',
  };

  handleChange = (e: Event) => {
    const {target} = e;
    // $FlowFixMe
    const {value} = target;
    this.setState({
      selectedValue: value,
    });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wagtail {
              properties {
                name
                soldout
                address {
                  neighborhood {
                    slug
                    name
                  }
                }
                floorplans {
                  units {
                    available
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const searchOptions = getNeighborhoodOptions(data);
          return (
            <Row
              margin="20px auto"
              width="65%"
              mediaQueries={mediaQueries}
              smWidth="90%"
            >
              <Dropdown
                options={searchOptions}
                width="80%"
                backgroundColor={WHITE}
                padding="20px 30px"
                arrow={dropdownArrow}
                defaultValue="Any Neighborhood"
                handleChange={e => this.handleChange(e)}
                selectedValue={this.state.selectedValue}
              />
              <Button
                buttonLink={`/search/?neighborhood=${stringToUrl(
                  this.state.selectedValue ? this.state.selectedValue : 'all',
                )}`}
                buttonText="search"
                buttonHeight="80px"
                buttonColor={ORANGE}
                buttonWidth="20%"
                smWidth="20%"
                fontSize="1.3rem"
              />
            </Row>
          );
        }}
      />
    );
  }
}
